import { render, staticRenderFns } from "./WatchDogHeader.vue?vue&type=template&id=fcb54d16&scoped=true&"
import script from "./WatchDogHeader.vue?vue&type=script&setup=true&lang=js&"
export * from "./WatchDogHeader.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./WatchDogHeader.vue?vue&type=style&index=0&id=fcb54d16&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcb54d16",
  null
  
)

export default component.exports